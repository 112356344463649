import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  TablePagination,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  FormControl,
  Menu,
  MenuItem,
  Button,
  InputLabel,
  Typography,
  TextField,
  Divider,
  InputAdornment,
  IconButton,
  Paper,
  Tooltip,
  Avatar,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

// ------ custom import files --------
import "./TableData.css";
import TableCardData from "../../../Constant/Dashboard.json";
import BioDetails from "../../ReuseComponents/Bio/BioDetails";
// import RiEditBoxFill from "../../../../src/Assets/Images/AppoinmentIcon/editIcon.svg";
// import MdDelete from "../../../../src/Assets/Images/DeletePopup";
import BookAppointment from "../../../Popup/BookAppointment";
import DeleteAppointment from "../../../Popup/DeletePopup";
import PropTypes from "prop-types";
import Controller from "../../../Controller/ApiController";
import UtilDateTime from "../../../Constant/utillDateTime";
import { Alert } from "../../../Components/ReuseComponents/Alert/Alertmessage";
import { useDispatch, useSelector } from "react-redux";
import delayIcon from "../../../Assets/icons/timeDelay.svg";
import { appointmentReferesh } from "../../Assets/Redux/features/Appointment/appointmentReferesh";
import { Link } from "react-router-dom";

const tableHeader = [
  "S.No",
  "Doctor Name",
  "Department",
  "Contact Number",
  "Assigned Nurse / Receptionist",
  "Settings Type",
  "Status",
  "Actions",
];

export default function SettingList(props) {
  const dispatch = useDispatch();
  const { opens, filterInQue, setUpdateTopWidget } = props;

  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [appointmentData, setAppointmentData] = useState([]);
  const [settingList, setSettingList] = useState({
    data: [
      {
        doctor_name: "Dr. AArav",
        department: "CARDIO",
        contact_number: "+91 9999988888",
        assigned_nurse_or_receptionist: "Dr. AArav",
        setting_type: "DEFAULT",
        status: "ACTIVE",
      },
    ],
    current_page: 1,
  });
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [updateStatusData, setUpdateStatusData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loaderopen, setloaderOpen] = React.useState(false);
  const [nextDayDate, setNextDayDate] = useState(false);

  const storedDate = useSelector((state) => state.dateFilter.date);

  let UserTypeAccess = localStorage.getItem("user_type")

  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  });

  const GetAppointmentList = async (pageNumber) => {
    handleLoaderOpen();
    const currentDate = new Date();
    // Convert to ISO string
    const startOfDayISOString = currentDate.toUTCString();
    let currentdate = new Date();

    currentdate = currentdate.setHours(0, 0, 0, 0);
    if (new Date(currentdate).toISOString() == storedDate) {
      currentdate = new Date().toISOString();
      setNextDayDate(true);
    } else {
      currentdate = storedDate;
      setNextDayDate(false);
    }

    var UID = localStorage.getItem("DoctorUid");
    var appointment_query = {
      "filter.doctor_uid": UID,
      page: pageNumber + 1,
      count: rowsPerPage,
      "filter.slot_status": filterInQue,
      "filter.slot_start_time": startOfDayISOString,
      "filter.keyword": searchKeyword,
      "filter.appointment_date": storedDate,
    };

    var slot_query = {
      page: pageNumber + 1,
      count: rowsPerPage,
      "filter.appointment_date": storedDate,
      "filter.booking_status": filterInQue,
      "filter.keyword": searchKeyword,
      "filter.doctor_uid": UID,
    };
    if (filterInQue == "OPEN") {
      slot_query = {
        ...slot_query,
        "filter.slot_start_time": currentdate,
        "filter.current_date": storedDate || "",
        "filter.slot_status": ["OPEN", "BLOCKED"],
      };
    }

    let response;
    if (filterInQue == "OPEN") {
      response = await Controller.ApiController(
        "GET",
        `/get_slot/${UID}`,
        "",
        slot_query
      );
    } else {
      response = await Controller.ApiController(
        "GET",
        "/appointment",
        "",
        slot_query
      );
      console.log(response, "response");
    }
    handleLoaderClose();
    if (response.type == "success") {
      setAppointmentData(response);
    } else {
      setAppointmentData({});
    }
    console.log(response, "dashboarddataList");
  };

  const refereshPage = () => {
    setCount((current) => current + 1);
    setUpdateTopWidget((current) => current + 1);
  };

  useEffect(() => {
    dispatch(appointmentReferesh(refereshPage));
  }, []);

  const UpdateStatus = async function (status, data) {
    handleLoaderOpen();

    let updateData = {
      booking_status: status,
    };
    // alert(JSON.stringify(datas));
    // alert(data?.appointment_uid);
    let res = await Controller.ApiController(
      "PUT",
      `/appointment/${data.appointment_uid}`,
      "",
      updateData
    );
    handleLoaderClose();
    if (res) {
      console.log(res, "dataListApi");

      if (res.type === "success") {
        handleClose();
        setCount((current) => current + 1);
        setUpdateTopWidget((current) => current + 1);
        // setAppointmentAddUpdate(prev => alert(prev));
        handleLoaderClose();
        Alert("success", "Appointment updated successfully");
      } else {
        handleLoaderClose();
        Alert("error", res.error.message);
      }
    }
  };

  const handleChange = (event) => {
    console.log("changed", event.target.value);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuIndex, setMenuIndex] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const handleChangePage = () => {};

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDeleteOpenModal = () => {
    setDeleteModal(true);
  };

  const handleDeleteCloseModal = () => {
    setDeleteModal(false);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    console.log(value);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const convertFirstLetterToUpperCase = (inputString) => {
    return inputString
      .toLowerCase()
      .split(/[ _]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} lg={12}>
          <Box
            sx={{
              padding: "10px 0px 0px 0px",
              marginBottom: "20px",
              display: { md: "flex", sm: "" },
            }}
          >
            <h3>Settings</h3>
          </Box>
        </Grid>
        <Grid
          sx={{ padding: "5px 0px 0px 0px" }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <BioDetails />
        </Grid>
        <Grid
          sx={{
            textAlign: { md: "right", xl: "right", lg: "right", xxl: "right" },
            display: "flex",
            justifyContent: "right",
          }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          mb={2}
        >
          {/* <div style={{ backgroundColor: "#EFEFEF", borderRadius: "50px", marginLeft:"-10px"}}> */}
          <TextField
            autoComplete="new-password"
            aria-label="Search"
            type="text"
            size="small"
            className="searchinput"
            value={searchKeyword}
            placeholder="Search"
            onChange={(e) => setSearchKeyword(e.target.value)}
            sx={{
              height: "40px",
              paddingTop: "0px",
              borderRadius: "50px",
              marginLeft: { xs: "20px" },
              backgroundColor: "#EFEFEF",
              "&:hover": {
                borderColor: "#fff",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#fff",
                display: "none",
              },
              "&:focus": {
                borderColor: "#fff",
                // display:"none"
              },
            }}
            inputProps={{
              style: { fontSize: 14 },
              autocomplete: "off",
              form: {
                autocomplete: "off",
              },
            }}
            InputProps={{
              autoComplete: "new-password",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon sx={{ color: "#757575" }} />
                </InputAdornment>
              ),
              endAdornment: searchKeyword && (
                <InputAdornment>
                  <CancelIcon
                    onClick={() => setSearchKeyword("")}
                    sx={{ cursor: "pointer" }}
                  />
                </InputAdornment>
              ),
            }}
          />
          {/* </div> */}
        </Grid>
        {/* {height > width ? (
          <Grid item xs={4} sm={4}>
            <Button variant="contained" startIcon={<FilterAltOutlinedIcon />}>
              Filter
            </Button>
          </Grid>
        ) : (
          ""
        )} */}
      </Grid>
      <Grid
        sx={{
          padding: "5px 0px 0px 0px",
          marginBottom: { xs: "50px", md: "0px" },
        }}
        container
        spacing={1}
      >
        <Grid item sm={12} md={12} lg={12}>
          <Paper sx={{ width: { sm: "100%", xs: "380px" } }}>
            <TableContainer>
              <Table
                stickyHeader
                sx={{ minWidth: { sm: "800px", xs: "300px" } }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow sx={{ whiteSpace: { sm: "normal", xs: "nowrap" } }}>
                    {tableHeader.map((RowHeadData, index) => (
                      <TableCell
                        style={{
                          color: "#676666",
                          fontSize: "14px",
                          padding: "10px",
                          // width:"550px",
                          backgroundColor: "#EEF0FA",
                        }}
                        key={index}
                      >
                        {RowHeadData}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody stickyHeader>
                  {settingList?.data?.length > 0 ? (
                    settingList?.data?.map((RowCellData, index) => (
                      <TableRow key={index}>
                        <TableCell className="RowCellData">
                          {index +
                            1 +
                            (settingList.current_page - 1) * rowsPerPage}
                        </TableCell>
                        <TableCell
                          sx={{ height: "50px" }}
                          className="RowCellData"
                        >
                          {RowCellData?.doctor_name ||
                            RowCellData?.doctor_name ||
                            "--"}
                        </TableCell>
                        <TableCell className="RowCellData">
                            {RowCellData?.department || RowCellData?.department || "--"}
                        </TableCell>
                        <TableCell className="RowCellData">
                        {RowCellData?.contact_number || RowCellData?.contact_number || "--"}
                        </TableCell>
                        <TableCell className="RowCellData">
                        {RowCellData?.assigned_nurse_or_receptionist || RowCellData?.assigned_nurse_or_receptionist || "--"}
                        </TableCell>
                        <TableCell className="RowCellData">
                        {RowCellData?.setting_type || RowCellData?.setting_type || "--"}
                        </TableCell>
                        <TableCell className="RowCellData">
                        {RowCellData?.status || RowCellData?.status || "--"}
                        </TableCell>
                        <TableCell className="RowCellData">
                        
                        <Box sx={{display:"flex",justifyContent:"left",marginLeft:"-5px"}} >
                               <Tooltip title="View" arrow slotProps={{tooltip: { sx: {backgroundColor:"#f5f5f9", color: "rgba(0, 0, 0, 0.87)",  border: '1px solid #dadde9', '& .MuiTooltip-arrow':{ color:"#f5f5f9"}}}}}> <Button  size="small" sx={{minWidth:"auto"}}>
                               <Link to={"/settings/view-settings"}> <Avatar sx={{width:"25px", height:"25px", backgroundColor:"#1A71FF"}}><VisibilityOutlinedIcon sx={{ color: "#fff", fontSize:"18px" }} /></Avatar></Link>
                                </Button></Tooltip>


                               
                               <Tooltip title="Edit" arrow slotProps={{tooltip: { sx: {backgroundColor:"#f5f5f9", color: "rgba(0, 0, 0, 0.87)",  border: '1px solid #dadde9', '& .MuiTooltip-arrow':{ color:"#f5f5f9"}}}}}>
                                <Button disabled = {UserTypeAccess == "SUPER_ADMIN" || UserTypeAccess == "ADMIN" ? false : true}
                                size="small" sx={{minWidth:"auto"}} >
                                <Link to={"/settings/view-settings"} ><Avatar sx={{width:"25px", height:"25px", backgroundColor: UserTypeAccess == "SUPER_ADMIN" || UserTypeAccess == "ADMIN" ? "#E77B18" : "grey"}}><EditIcon sx={{ color: "#fff", fontSize:"18px" }} /></Avatar></Link>
                                </Button>
                                </Tooltip> 
                                

                                <Tooltip title="Delete" arrow slotProps={{tooltip: { sx: {backgroundColor:"#f5f5f9", color: "rgba(0, 0, 0, 0.87)",  border: '1px solid #dadde9', '& .MuiTooltip-arrow':{ color:"#f5f5f9"}}}}}>

                                <Button disabled = {UserTypeAccess == "SUPER_ADMIN" || UserTypeAccess == "ADMIN" ? false : true}
                                 size="small" sx={{minWidth:"auto"}}>
                                <Avatar sx={{width:"25px", height:"25px", backgroundColor: UserTypeAccess == "SUPER_ADMIN" || UserTypeAccess == "ADMIN" ?"#BC123E" : "grey"}}><DeleteOutlinedIcon sx={{ color: "#fff", fontSize:"18px" }} /></Avatar>
                                </Button>
                                </Tooltip>
                              </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        sx={{ textAlign: { md: "center", xs: "start" } }}
                        colSpan={7}
                      >
                        No Appointment Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          {appointmentData.total_records > 0 && (
            <TablePagination
              component="div"
              count={appointmentData.total_records}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Grid>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );

  // TableData.propTypes = {
  //   page: PropTypes.number,
  //   : PropTypes.function,
  //   list: PropTypes.function,
  // };
}
