import React, { useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
  Select,
  FormControlLabel,
  RadioGroup,
  Radio,
  setRef,
  Card,
  LinearProgress,
  IconButton,
} from "@mui/material";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "../Assets/css/custom-class.css";
import validationRegExp from "../Configuration/ValidationConfig";
import PropTypes from "prop-types";
import Controller from "../Controller/ApiController";
import UtilDateTime from "../Constant/utillDateTime";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "../Components/ReuseComponents/Alert/Alertmessage";
import "../Popup/bookappointment.css";
import dayjs from "dayjs";
import CancelAppointmentChild from "./CancelAppointmentChild";
import cancellIcon from "../Assets/images/DeletePopup/cancelIcon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { BasicSelect } from "../Components/ReuseComponents/Select/BasicSelect";
import { OutlinedTextField } from "../Components/ReuseComponents/TextField/BasicTextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "650px !important",
  height: "auto",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  "@media (min-width: 720px) ": {
    width: "80%",
    // height:"auto",
  },
};

export default function BookAppointment({
  open,
  onClose,
  type,
  data,
  setAppointmentAddUpdate,
  fetchGetAPIData,
  openModalCancel,
  closeModalCancel,
}) {
  // console.log("fetchGetAPIData: ",fetchGetAPIData)

  const [patientNameError, setPatientNameError] = useState("");
  const [selectSlotError, setSelectSlotError] = useState("");
  const [lineProgressLoading, setLineProgressLoading] = useState(true);
  const [openCancelModel, setOpenCancelModel] = useState(false);
  const [radioDisabled, setRadioDisabled] = React.useState(true);
  const [mobileError, setMobileError] = useState("");
  const mobileFieldFocus = useRef(null);
  const nameFieldFocus = useRef(null);
  const mobileTypeFocus = useRef(null);
  const [slotTimeFromTo, setSlotTimeFromTo] = useState([]);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  var accessPermissonValueGet = useSelector(
    (state) => state.accessPermissionValue.access_values
  );
  const manageApiRef = useSelector(
    (state) => state.manageAppointmentSlice.appointment
  );

  useEffect(() => {
    console.log(accessPermissonValueGet, "accessPermissonValueGet");
  }, [accessPermissonValueGet]);

  useEffect(() => {
    console.log(manageApiRef, "manageApiRef");
  }, [manageApiRef]);

  const Location = useLocation();
  const navigate = useNavigate();
  var initialStateErrors = {
    patientName: { required: false },
    mobileNumber: { required: false },
  };

  const [loaderopen, setloaderOpen] = React.useState(false);
  const storedDate = useSelector((state) => state.dateFilter.date);

  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  const [errors, setErrors] = useState(initialStateErrors);
  // var newdate = ;

  const [formData, setFormData] = useState({
    consultation_type: data?.consultation_type
      ? data?.consultation_type
      : "NORMAL",
    appointment_type: data?.appointment_type ? data?.appointment_type : "",
    appointment_date: storedDate,
    slot_uid: data?.slot.slot_uid ? data?.slot.slot_uid : "default",
    patient_name: data?.patient_name ? data?.patient_name : "",
    mobile_number: data?.mobile_number ? data?.mobile_number : "",
    smart_phone: data?.smart_phone ? data?.smart_phone : true,
    payment_mode: data?.payment_mode ? data?.payment_mode : "",
    booking_source: data?.booking_source ? data?.booking_source : "WEB_API",
    visiting_status: data?.visiting_status ? data?.visiting_status : "NEW",
  });

  const cancelModel = () => {
    setOpenCancelModel(true);
  };

  const cancelModelclose = () => {
    setOpenCancelModel(false);
    onClose();
  };

  const cancelModelcloseChild = () => {
    setOpenCancelModel(false);
  };

  let currentdate = new Date();
  currentdate = currentdate.setHours(0, 0, 0, 0);
  // useEffect(()=>{
  //   if(pathname == "/manageappointments"){

  //   }
  // },[])

  const getSlotTime = async () => {
    handleLoaderOpen();
    let docteruid = localStorage.getItem("DoctorUid");

    let currentdate = new Date();
    currentdate = currentdate.setHours(0, 0, 0, 0);

    if (new Date(currentdate).toISOString() == storedDate) {
      currentdate = new Date().toISOString();
    } else {
      currentdate = storedDate;
    }

    let reqParams = {
      "filter.slot_start_time": currentdate,
      "filter.paginate": "NO",
      "filter.consultation_type": formData.consultation_type,
      "filter.current_date": storedDate || "",
    };
    // if(formData.consultation_type == "NORMAL") {
    //   reqParams = {...reqParams, "filter.C": "OPEN"}
    // }

    try {
      let res = await Controller.ApiController(
        "GET",
        `/get_slot/${docteruid}`,
        "?",
        reqParams
      );
      handleLoaderClose();
      // console.log(res, "slotTimedata");
      if (res && res.data && Array.isArray(res.data)) {
        // console.log(res.data , "slotidGetdata")
        setLineProgressLoading(false);
        // console.log(res.data, "slotTimedata");

        const slotTimeListdata = res.data.map((item) => ({
          slot_uid: item.slot_uid,
          start_time: item.slot_start_time,
          end_time: item.slot_end_time,
        }));

        // if(formData.consultation_type == "EMERGENCY" && type != "editUser") {
        // setFormData({
        //   ...formData,
        //   slot_uid: slotTimeListdata[0].slot_uid,
        // });
        // }
        setSlotTimeFromTo(slotTimeListdata || []);
      }
    } catch (error) {
      handleLoaderClose();
      console.log(error, "slotTimedata");
    }
  };

  useEffect(() => {
    if (type != "editUser") {
      if (formData.consultation_type == "EMERGENCY") {
        getSlotTime();
        setSelectSlotError("");
      } else {
        setFormData((prev) => ({
          ...prev,
          slot_uid: data?.slot.slot_uid ? data?.slot.slot_uid : "default",
        }));
      }
    }
  }, [formData.consultation_type]);

  // Initial state error validation

  useEffect(() => {
    if (formData.patient_name != "" && errors.patientName.required) {
      setErrors({ ...errors, patientName: { required: false } });
    } else if (errors.patientName.required) {
      setErrors({ ...errors, patientName: { required: true } });
    }
  }, [formData.patient_name]);

  useEffect(() => {
    if (formData.mobile_number != "" && errors.mobileNumber.required) {
      setErrors({ ...errors, mobileNumber: { required: false } });
    } else if (errors.mobileNumber.required) {
      setErrors({ ...errors, mobileNumber: { required: true } });
    }
  }, [formData.mobile_number]);

  const clearInputFields = () => {
    formData.consultation_type = "NORMAL";
    formData.appointment_type = "";
    formData.appointment_date = "";
    formData.slot_uid = "";
    formData.patient_name = "";
    formData.mobile_number = "";
    formData.smart_phone = true;
    formData.payment_mode = "";
    formData.visiting_status = "";
  };

  const validateFields = () => {
    let patientNameValidate = false;
    let mobileValidate = false;
    let slotValidate = false;

    if (formData.patient_name == "") {
      setPatientNameError(" ");
    } else if (formData.patient_name) {
      let validInput = false;
      if (type == "editUser") {
        validInput = validationRegExp.fullNameRegEdit.test(
          formData.patient_name
        );
      } else {
        validInput = validationRegExp.fullNameReg.test(formData.patient_name);
      }
      if (!validInput) {
        setPatientNameError("Invalid patient name");
      } else {
        setPatientNameError("");
        patientNameValidate = true;
      }
    }

    if (formData.slot_uid == "default") {
      setSelectSlotError(" ");
    } else {
      setSelectSlotError("");
      slotValidate = true;
    }

    if (formData.mobile_number == "") {
      setMobileError(" ");
    } else if (formData.mobile_number) {
      let validationCheck = false;
      if (type == "editUser") {
        validationCheck = validationRegExp.mobileNumberEditReg.test(
          formData.mobile_number
        );
      } else {
        validationCheck = validationRegExp.mobileNumberReg.test(
          formData.mobile_number
        );
      }
      if (validationCheck) {
        let mobile = "";
        let firstIndex =
          formData.mobile_number[0] == undefined
            ? ""
            : formData.mobile_number[0];
        var repeat = true;
        for (var i = 0; i < formData.mobile_number.length; i++) {
          if (firstIndex != formData.mobile_number[i]) {
            repeat = false;
          }
        }
        for (let i = 0; i < formData.mobile_number.length; i++) {
          mobile +=
            formData.mobile_number[i] != " " ? formData.mobile_number[i] : "";
        }
        if (mobile.length > 9 || mobile == "") {
          if (repeat && mobile != "") {
            setMobileError("Invalid number");
          } else {
            setMobileError("");
            mobileValidate = true;
          }
        } else {
          setMobileError("Mobile number must be 10 digit");
        }
      }
    }
    return patientNameValidate && mobileValidate && slotValidate;
  };

  const appointmentData = useSelector(
    (state) => state.appointmentSlice.appointment
  );
  console.log("appointmentReferesh: ", appointmentData);
  useEffect(() => {
    console.log(appointmentData);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let focusField = true;

    const validation = validateFields();
    if (!validation) {
      return;
    }
    handleLoaderOpen();
    if (type == "editUser") {
      let datas = { ...formData };
      if (data?.slot.slot_uid == datas.slot_uid) delete datas.slot_uid;
      // alert(JSON.stringify(datas));
      // alert(data?.appointment_uid);
      let res = await Controller.ApiController(
        "PUT",
        `/appointment/${data?.appointment_uid}`,
        "",
        datas
      );

      if (res) {
        console.log(JSON.stringify(res, "dataListApi"));

        if (res.type === "success") {
          handleLoaderClose();
          setDisabledSubmit(true);
          clearInputFields();
          onClose();
          fetchGetAPIData(); // setAppointmentAddUpdate(prev => alert(prev));
          Alert("success", "Appointment updated successfully");
        } else {
          handleLoaderClose();
          Alert("error", "something went wrong");
        }
      }
    } else {
      handleLoaderOpen();
      let data = { ...formData };
      let res = await Controller.ApiController(
        "POST",
        "/appointment",
        "",
        data
      );

      if (res) {
        console.log(JSON.stringify(res, "dataListApi"));

        if (res.type === "success") {
          handleLoaderClose();
          setDisabledSubmit(true);
          Alert("success", "Appointment booked successfully");
          onClose();
          // setTimeout(() => {
          // setRefGetDataListing(refGetDataListing)

          clearInputFields();
          if (
            accessPermissonValueGet.includes("MANAGE_APPOINTMENTS") &&
            Location.pathname == "/manageappointments"
          ) {
            manageApiRef();
          } else if (Location.pathname == "/dashboard") {
            appointmentData();
          } else {
            navigate(
              accessPermissonValueGet.includes("DASHBOARD")
                ? "/dashboard"
                : "/manageappointments"
            );
          }

          // window.location.reload()
          // }, 3500);

          // setAppointmentAddUpdate((current) => current + 1);
        } else {
          handleLoaderClose();
          Alert("error", res.error.message);
        }
        handleLoaderClose();
      }
    }
  };

  const slotTime = (e) => {
    // if(formData.consultation_type == "NORMAL") {
    setFormData({
      ...formData,
      slot_uid: e.target.value,
    });
    // }
    if (selectSlotError) {
      setSelectSlotError("");
    }
  };

  // Mobile number validation function

  const mobileOnChange = (e) => {
    if (validationRegExp.mobileNumberReg.test(e.target.value)) {
      setFormData({
        ...formData,
        mobile_number: e.target.value,
      });
      if (mobileError) {
        setMobileError("");
      }
    }
  };

  const fullNameOnChange = (e) => {
    var validationCheckName =
      type == "editUser"
        ? validationRegExp.fullNameRegEdit.test(e.target.value)
        : validationRegExp.fullNameReg.test(e.target.value);
    if (validationCheckName) {
      setFormData({
        ...formData,
        patient_name: e.target.value,
      });
      if (patientNameError) {
        setPatientNameError("");
      }
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
      >
        <Backdrop
          open={open}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Paper
            className="bookmodal"
            sx={{
              width: { xs: "95%", sm: "95%" },
              maxWidth: "700px", // Set the maximum width for larger screens
              margin: "auto",
            }}
          >
            <Grid sx={{ borderRadius: "15px" }} container>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: "#EEF0FA",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                }}
              >
                <Box
                  sx={{
                    padding: { md: "10px 25px", xs: "10px 10px" },
                    display: "flex",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#243665",
                      marginLeft: "0.6px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {type == "editUser"
                      ? "Edit an Appointment"
                      : "Book an Appointment"}
                  </Typography>
                  <Typography
                    sx={{
                      display: { xs: "block", sm: "none" },
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#243665",
                      marginLeft: "0.6px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {" "}
                    &nbsp;-&nbsp;
                    {UtilDateTime.formatYearMonthAndDayAsInt(storedDate)}{" "}
                  </Typography>
                  <Typography
                    sx={{
                      display: { xs: "none", sm: "block" },
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#243665",
                      marginLeft: "0.6px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {" "}
                    &nbsp;-&nbsp;{UtilDateTime.formatMonthDateYear(
                      storedDate
                    )}{" "}
                  </Typography>
                  {/* <Typography sx={{display:{xs:"block",sm:"none"},fontSize: "18px",fontWeight: "600",  color: "#243665", marginLeft:"0.6px", whiteSpace:"nowrap" }}>{UtilDateTime.formatMonthDateYear(storedDate)}</Typography> */}
                </Box>
              </Grid>
              <hr />
              <Grid item md={12} xs={12} sm={12}>
                <Box component="form" onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ padding: { md: "0px 25px", xs: "0px 10px" } }}
                  >
                    <Grid item xs={12}>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={6} sm={3} md={2.5}>
                          <FormLabel
                            sx={{
                              color: "#000000",
                              "&.Mui-focused": { color: "#000000" },
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                              fontWeight: "600",
                            }}
                            lab
                            id="demo-radio-buttons-group-label"
                          >
                            Consultation Type
                          </FormLabel>
                        </Grid>
                        <Grid item xs={7} sm={9} md={9.5}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={
                              formData.consultation_type
                                ? formData.consultation_type
                                : ""
                            }
                            onChange={(e) => {
                              let consultationType = e.target.value;

                              setFormData({
                                ...formData,
                                consultation_type: consultationType,
                              });
                            }}
                            name="consultation_type"
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "14px",
                                fontWeight: "600",
                              },
                              "& .MuiFormControlLabel-root": {
                                // display: "inline-flex",
                                // alignItems: "start",
                                margin: 0,
                                marginRight: "10px",
                                height: "30px",
                                "& .MuiRadio-root": {
                                  padding: 0, // Custom color for radio button
                                  paddingRight: "9px",
                                },
                              },
                            }}
                          >
                            <FormControlLabel
                              value="NORMAL"
                              control={<Radio size="small" />}
                              label="Normal"
                              className="radioGroupFont"
                            />
                            <FormControlLabel
                              value="EMERGENCY"
                              disabled={
                                type == "editUser" ||
                                storedDate !=
                                  new Date(currentdate).toISOString()
                                  ? true
                                  : false
                              }
                              control={<Radio size="small" />}
                              label="Emergency"
                            />
                            <FormControlLabel
                              value="REVIEW_APPOINTMENT"
                              control={<Radio size="small" />}
                              label="Review Appointment"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      display={
                        formData.consultation_type != "REVIEW_APPOINTMENT" &&
                        "none"
                      }
                    >
                      <Grid container alignItems="center">
                        <Grid item xs={4.5} sm={3} md={5}>
                          <FormLabel
                            htmlFor="availableSlot"
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              whiteSpace: "nowrap",
                              color: selectSlotError ? "#d32f2f" : "#000000",
                              // backgroundColor:"red"
                            }}
                          >
                            Appointment Type
                          </FormLabel>
                        </Grid>
                        <Grid item xs={7.5} sm={9} md={7}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={
                              formData.appointment_type
                                ? formData.appointment_type
                                : "SINGLE"
                            }
                            onChange={(e) => {
                              let consultationType = e.target.value;

                              setFormData({
                                ...formData,
                                appointment_type: consultationType,
                              });
                            }}
                            name="appointment_type"
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "14px",
                              },
                              "& .MuiFormControlLabel-root": {
                                // display: "inline-flex",
                                // alignItems: "start",
                                margin: 0,
                                marginRight: "10px",
                                height: "30px",
                                "& .MuiRadio-root": {
                                  padding: 0, // Custom color for radio button
                                  paddingRight: "9px",
                                },
                              },
                            }}
                          >
                            <FormControlLabel
                              value="SINGLE"
                              control={<Radio size="small" />}
                              label="Single"
                            />
                            <FormControlLabel
                              value="MULTIPLE"
                              control={<Radio size="small" />}
                              label="Multiple"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={4.5} sm={3} md={5}>
                          <FormLabel
                            htmlFor="availableSlot"
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              whiteSpace: "nowrap",
                              color: selectSlotError ? "#d32f2f" : "#000000",
                              // backgroundColor:"red"
                            }}
                          >
                            Doctor Name
                          </FormLabel>
                        </Grid>
                        <Grid item xs={7.5} sm={9} md={7}>
                          <BasicSelect value="default">
                            <MenuItem value="default" sx={{ display: "none" }}>
                              Select
                            </MenuItem>
                          </BasicSelect>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={4.5} sm={3} md={5}>
                          <FormLabel
                            htmlFor="e"
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              whiteSpace: "nowrap",
                              color: selectSlotError ? "#d32f2f" : "#000000",
                              // backgroundColor:"red"
                            }}
                          >
                            Open Date
                          </FormLabel>
                        </Grid>
                        <Grid item xs={7.5} sm={9} md={7}>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
                            <DesktopDatePicker
                              // sx={{ height: "30px" }}
                              slotProps={{
                                textField: {
                                  sx: {
                                    "& .MuiOutlinedInput-root": {
                                      width: "100%",
                                      height: "30px",
                                      "& fieldset": {
                                        borderColor: "#C4C4C4",
                                      },
                                      color: "#000",
                                      fontFamily: "Segoe UI",
                                      "&:hover fieldset": {
                                        borderColor: "#000",
                                      },
                                      "&.Mui-focused fieldset": {
                                        border: '1px solid #C4C4C4',
                                      },
                                    },
                                  },
                                  size: "small"
                                },
                              }}
                              defaultValue={dayjs(new Date())}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={4.5} sm={3} md={5}>
                          <FormLabel
                            htmlFor="availableSlot"
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              whiteSpace: "nowrap",
                              color: selectSlotError ? "#d32f2f" : "#000000",
                              // marginRight: "30px",
                              // backgroundColor:"red"
                            }}
                          >
                            Open Slot
                          </FormLabel>
                        </Grid>
                        <Grid item xs={7.5} sm={9} md={7}>
                          <BasicSelect
                            value={formData.slot_uid}
                            onChange={slotTime}
                            onOpen={getSlotTime}
                          >
                            {type === "editUser" ? (
                              <MenuItem
                                sx={{ fontSize: "14px" }}
                                value={formData.slot_uid}
                              >
                                {UtilDateTime.formatTimeAsHHMMTT(
                                  data?.slot.slot_start_time
                                ) +
                                  "-" +
                                  UtilDateTime.formatTimeAsHHMMTT(
                                    data?.slot.slot_end_time
                                  )}
                              </MenuItem>
                            ) : (
                              <MenuItem
                                sx={{ fontSize: "14px", display: "none" }}
                                value="default"
                              >
                                Select
                              </MenuItem>
                            )}

                            {slotTimeFromTo.map((item, i) => (
                              <MenuItem
                                sx={{ fontSize: "14px" }}
                                key={i}
                                value={item.slot_uid}
                              >
                                {UtilDateTime.formatTimeAsHHMMTT(
                                  item.start_time
                                ) +
                                  "-" +
                                  UtilDateTime.formatTimeAsHHMMTT(
                                    item.end_time
                                  )}
                              </MenuItem>
                            ))}
                            {lineProgressLoading && (
                              <Box sx={{ width: "100%" }}>
                                <LinearProgress />
                              </Box>
                            )}
                          </BasicSelect>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      display={{
                        xs: "none",
                        md:
                          formData.consultation_type != "REVIEW_APPOINTMENT"
                            ? "block"
                            : "none",
                      }}
                      md={6}
                    ></Grid>

                    {/* <Grid item>
                      <Box display={"flex"} alignItems={"center"}>
                        <Box sx={{ marginBottom: "18px" }}>
                          <FormLabel
                            htmlFor="availableSlot"
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              color: selectSlotError ? "#d32f2f" : "#000000",
                              // backgroundColor:"red"
                            }}
                          >
                            Select Open Slot*
                          </FormLabel>
                        </Box>
                        <FormControl>
                          <Select
                            className="groupField"
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            sx={{
                              width: {
                                xs: "40vw",
                                md: "20vw",
                                md: "16vw",
                                lg: "13vw",
                                xl: "13vw",
                                xxl: "13vw",
                              },
                              backgroundColor:
                                type == "editUser" ? "#454545" : "#1A71FF",
                              color: "#FFFFFF",
                              "& .MuiSelect-icon": {
                                color: "#FFFFFF",
                              },
                              height: "40px",
                              fontSize: "14px",
                              marginLeft: "35px",
                            }}
                            value={formData.slot_uid}
                            onChange={slotTime}
                            onOpen={getSlotTime}
                          >
                            {type === "editUser" ? (
                              <MenuItem
                                sx={{ fontSize: "14px" }}
                                value={formData.slot_uid}
                              >
                                {UtilDateTime.formatTimeAsHHMMTT(
                                  data?.slot.slot_start_time
                                ) +
                                  "-" +
                                  UtilDateTime.formatTimeAsHHMMTT(
                                    data?.slot.slot_end_time
                                  )}
                              </MenuItem>
                            ) : (
                              <MenuItem
                                sx={{ fontSize: "14px" }}
                                value="default"
                                disabled
                              >
                                Select slot
                              </MenuItem>
                            )}

                            {slotTimeFromTo.map((item, i) => (
                              <MenuItem
                                sx={{ fontSize: "14px" }}
                                key={i}
                                value={item.slot_uid}
                              >
                                {UtilDateTime.formatTimeAsHHMMTT(
                                  item.start_time
                                ) +
                                  "-" +
                                  UtilDateTime.formatTimeAsHHMMTT(
                                    item.end_time
                                  )}
                              </MenuItem>
                            ))}
                            {lineProgressLoading && (
                              <Box sx={{ width: "100%" }}>
                                <LinearProgress />
                              </Box>
                            )}
                          </Select>
                          <Typography
                            sx={{
                              lineHeight: "0px",
                              color: "red",
                              marginTop: "15px",
                              fontSize: "14px",
                            }}
                          >
                            {selectSlotError}
                          </Typography>
                        </FormControl>
                      </Box>
                    </Grid> */}

                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={4.5} sm={3} md={5}>
                          <FormLabel
                            htmlFor="patientName"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: patientNameError ? "#d32f2f" : "#000000",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Patient Name*
                          </FormLabel>
                        </Grid>
                        <Grid item xs={7.5} sm={9} md={7}>
                          <OutlinedTextField
                            id="patient_name"
                            className="patientname"
                            placeholder="Enter patient name"
                            name="patient_name"
                            variant="outlined"
                            size="small"
                            error={patientNameError ? patientNameError : ""}
                            ref={nameFieldFocus}
                            value={
                              formData.patient_name ? formData.patient_name : ""
                            }
                            onChange={(e) => fullNameOnChange(e)}
                          />

                          <Typography className="errorValidationTextMobile">
                            {patientNameError}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={4.5} sm={3} md={5}>
                          <FormLabel
                            htmlFor="patientName"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: mobileError ? "#d32f2f" : "#000000",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Mobile Number*
                          </FormLabel>
                        </Grid>
                        <Grid item xs={7.5} sm={9} md={7}>
                          <OutlinedTextField
                            id="patientName"
                            className="mobilenumber"
                            variant="outlined"
                            placeholder="Enter mobile number"
                            size="small"
                            name="mobile_number"
                            error={mobileError ? mobileError : ""}
                            sx={{
                              width: "100%",
                              // height: "40px",
                              fontSize: "14px",
                              "& .MuiOutlinedInput-root": {
                                fontSize: "14px",
                                "&.Mui-focused fieldset": {
                                  borderColor: "#C8C7C7", // border color on focus
                                },
                              },
                            }}
                            ref={mobileFieldFocus}
                            value={
                              formData.mobile_number
                                ? formData.mobile_number
                                : ""
                            }
                            onChange={(e) => mobileOnChange(e)}
                          />
                          {
                            <Typography className="errorValidationTextMobilePopupBook">
                              {mobileError}
                            </Typography>
                          }
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display:
                          formData.consultation_type == "REVIEW_APPOINTMENT" &&
                          formData.appointment_type == "MULTIPLE"
                            ? "block"
                            : "none",
                      }}
                    >
                      <Grid container alignItems="center">
                        <Grid item xs={4.5} sm={3} md={5}>
                          <FormLabel
                            htmlFor="patientName"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: mobileError ? "#d32f2f" : "#000000",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Repeat Every
                          </FormLabel>
                        </Grid>
                        <Grid item xs={7.5} sm={9} md={7}>
                          <OutlinedTextField
                            id="patientName"
                            variant="outlined"
                            placeholder=""
                            size="small"
                            error={mobileError ? mobileError : ""}
                            sx={{
                              width: "70px",
                            }}
                            ref={mobileFieldFocus}
                            value={
                              formData.mobile_number
                                ? formData.mobile_number
                                : ""
                            }
                            onChange={(e) => mobileOnChange(e)}
                          />
                          <BasicSelect
                            value="s"
                            sx={{ width: "75px", marginLeft: "10px" }}
                          >
                            <MenuItem value="default" sx={{ display: "none" }}>
                              Select
                            </MenuItem>
                          </BasicSelect>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display:
                          formData.consultation_type == "REVIEW_APPOINTMENT" &&
                          formData.appointment_type == "MULTIPLE"
                            ? "block"
                            : "none",
                      }}
                    >
                      <Grid container alignItems="center">
                        <Grid item xs={4.5} sm={3} md={5}>
                          <FormLabel
                            htmlFor="patientName"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: mobileError ? "#d32f2f" : "#000000",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Occurance
                          </FormLabel>
                        </Grid>
                        <Grid item xs={7.5} sm={9} md={7}>
                          <OutlinedTextField
                            id="patientName"
                            className="mobilenumber"
                            variant="outlined"
                            placeholder="0"
                            size="small"
                            name="mobile_number"
                            error={mobileError ? mobileError : ""}
                            sx={{
                              width: "80px",
                              // height: "40px",
                              fontSize: "14px",
                              "& .MuiOutlinedInput-root": {
                                fontSize: "14px",
                                "&.Mui-focused fieldset": {
                                  borderColor: "#C8C7C7", // border color on focus
                                },
                              },
                            }}
                            ref={mobileFieldFocus}
                            value={
                              formData.mobile_number
                                ? formData.mobile_number
                                : ""
                            }
                            onChange={(e) => mobileOnChange(e)}
                          />
                          {
                            <Typography className="errorValidationTextMobilePopupBook">
                              {mobileError}
                            </Typography>
                          }
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Box display={"flex"} alignItems={"center"}>
                        <Button
                          sx={{
                            mt: 3,
                            mb: 2,
                            mr: 2,
                            backgroundColor: "#D13C3C",
                            color: "#FFFFFF",
                            border: "1px solid #FFF",
                            borderRadius: "50px",
                            height: "40px",
                            "&:hover": { background: "#D13C3C" },
                            width: "154px",
                          }}
                          onClick={cancelModel}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          disabled={disabledSubmit}
                          sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: "#41BA8F",
                            color: "#FFFFFF",
                            border: "1px solid #FFF",
                            borderRadius: "50px",
                            height: "40px",
                            "&:hover": { background: "#41BA8F" },
                            width: "154px",
                          }}
                        >
                          {type == "editUser" ? "Submit" : "Confirm"}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>

                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loaderopen}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Backdrop>
      </Modal>
      {openCancelModel && (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          open={cancelModel}
        >
          <Backdrop
            open={cancelModel}
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <Paper sx={{ width: "350px", borderRadius: "10px" }}>
              <Grid sx={{ borderRadius: "15px" }} container>
                <Grid
                  item
                  xs={12}
                  sx={{ backgroundColor: "#EEF0FA", borderRadius: "10px" }}
                >
                  <Box
                    sx={{
                      height: "70px",
                      backgroundColor: "#FFD7D7",
                      borderRadius: "10px 10px 0px 0px",
                    }}
                  >
                    <IconButton
                      sx={{
                        marginTop: "35px",
                        marginLeft: "142px",
                        backgroundColor: "#D13C3C",
                        height: "54px",
                        width: "55px",
                        "&:hover": { backgroundColor: "#D13C3C" },
                        cursor: "default",
                      }}
                    >
                      <img src={cancellIcon} alt="deleteicon" />
                    </IconButton>
                  </Box>
                </Grid>
                <hr />
                <Grid item md={12} xs={12} sm={12} sx={{ padding: "0% 5% 5%" }}>
                  <Box>
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <Typography
                          mt={2}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Are you sure you want
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          to cancel the activity ?
                        </Typography>
                      </Grid>
                    </Grid>

                    <Box>
                      <Box
                        sx={{ display: "flex", justifyContent: "center" }}
                        mt={2}
                      >
                        <Button
                          sx={{
                            mr: 2,
                            backgroundColor: "#000000",
                            color: "#FFFFFF",
                            height: "25px",
                            "&:hover": { background: "#000000" },
                            width: "95px",
                            fontSize: "13px",
                            fontWeight: 600,
                            borderRadius: "30px",
                            textTransform: "none",
                          }}
                          onClick={cancelModelcloseChild}
                        >
                          No
                        </Button>
                        <Button
                          sx={{
                            backgroundColor: "#41BA8F",
                            color: "#FFFFFF",
                            height: "25px",
                            "&:hover": { background: "#41BA8F" },
                            width: "95px",
                            fontSize: "13px",
                            fontWeight: 600,
                            borderRadius: "30px",
                            textTransform: "none",
                          }}
                          onClick={cancelModelclose}
                        >
                          Yes
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Backdrop>
        </Modal>
      )}
      {/* {openCancelModel && (
        <CancelAppointmentChild
          openModalCancel={cancelModel}
          closeModalCancel={cancelModelclose}
          cancelModelcloseChild={cancelModelcloseChild}
        />
      )} */}
    </>
  );
}
