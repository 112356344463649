import React, { useEffect, useState } from "react";
import {
  alpha,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  TextField,
  Typography,
} from "@mui/material";

// ---- custom Files ------
import "./biodetails.css";

// ------ custom icons and images ------
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Calender from "../../../Assets/dashboard/calender.svg";
import Hospital from "../../../Assets/dashboard/hospital.svg";
import Doctor from "../../../Assets/dashboard/doctor.svg";
import { useLocation } from "react-router-dom";
import Controller from "../../../Controller/ApiController";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { updateLoggedInData } from "../../Assets/Redux/features/StoreData/userLoginDetails";
import { BasicSelect, MultipleSelect } from "../Select/BasicSelect";
import { BasicMenuItem } from "../Select/MenuItem";
import {
  DesktopDatePicker,
  LocalizationProvider,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DateRange } from "@mui/icons-material";
import { BasicButton, TextButton } from "../Button/BasicButton";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    maxHeight: "220px",
    marginTop: theme.spacing(1),
    width: 300,
    color: "rgb(55, 65, 81)",
    backgroundColor: "#fff",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      backgroundColor: "#fff",
    },
    "& .MuiMenuItem-root": {
      padding: "5px",
      "&:hover": {
        backgroundColor: "#fff",
      },
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
    // ...theme.applyStyles("dark", {
    //   color: theme.palette.grey[300],
    // }),
  },
}));

const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

export default function BioDetails() {
  const initialDoctorList = [
    { doctor_name: "Aarav" },
    { doctor_name: "Neha" },
    { doctor_name: "Rohan" },
  ];

  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState("");
  const currentPath = useLocation();
  const [selectedDate, setSelectedDate] = useState("");
  const [customDate, setCustomDate] = useState({
    from: dayjs(new Date()).subtract(1, "day"),
    to: dayjs(new Date()),
  });
  const [menuItemValue, setMenuItemValue] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [topWidgetData, setTopWidgetData] = useState();
  const [hospitalData, setHospitalData] = useState({});
  const storedDate = useSelector((state) => state.dateFilter.date);
  const [doctorList, setDoctorList] = useState(initialDoctorList);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const currentDate = dayjs();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const FullName = localStorage.getItem("full_name");
  const DoctorName = localStorage.getItem("user_name");
  const HospitalName = localStorage.getItem("hospital_name");

  const dispatch = useDispatch();

  const formDatas = useSelector(
    (state) => state.userLoginDetails.loggedInDetails
  );

  useEffect(() => {
    async function GetTopWidgetData() {
      var UID = localStorage.getItem("DoctorUid");
      var Data = {
        "filter.doctor_uid": UID,
        "filter.date": storedDate,
      };
      let data = {};
      let response = await Controller.ApiController("GET", `/dashboard/${UID}`);
      if (response.type == "success") {
        setHospitalData(response.data);
      }
      console.log(response, "grgr");
    }
    GetTopWidgetData();
  }, [storedDate]);

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  });

  useEffect(() => {
    console.log(topWidgetData, "sss");
  }, [topWidgetData]);

  useEffect(() => {
    if (storedDate) {
      setCurrentDateTime(new Date(storedDate));
    } else {
      setCurrentDateTime(new Date());
    }
    const intervalId = setInterval(() => {
      if (storedDate) {
        setCurrentDateTime(new Date(storedDate));
      } else {
        setCurrentDateTime(new Date());
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [storedDate]);

  const formattedDateTime = (date) => {
    return new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",

      // hour: 'numeric',
      // minute: 'numeric',
      // hour12: true,
    }).format(date);
  };

  useEffect(() => {
    setSelectedDate(formattedDateTime(currentDate));
  }, []);

  const handlechange = (e) => {
    const { value, name } = e.target;
    let filerValue = typeof value === "string" ? value.split(",") : value;
    filerValue = filerValue.filter((val) => val != "Select");
    setSelectedDoctors(filerValue);
  };

  const handleDatechange = (key) => {
    let value = formattedDateTime(currentDate);
    if (key == "TODAY") {
      value = formattedDateTime(currentDate);
    } else if (key == "CURRENT_WEEK") {
      value =
        formattedDateTime(dayjs(currentDate.subtract(1, "week"))) +
        " to " +
        formattedDateTime(currentDate);
    } else if (key == "CURRENT_MONTH") {
      value =
        formattedDateTime(dayjs(currentDate.subtract(1, "month"))) +
        " to " +
        formattedDateTime(currentDate);
    } else if (key == "CUSTOM") {
      value =
        formattedDateTime(dayjs(customDate.from)) +
        " to " +
        formattedDateTime(dayjs(customDate.to));
    }
    setSelectedDate(value);
    handleClose();
  };

  return (
    <>
      {/* <Grid container  sx={{display:{xs:"flex",lg:"flex", md:"flex"}, justifyContent:{lg:"left", md:"left"}}} >
        
          
          
          <Grid  className="flx" item sm={6} xs={12} md={4} lg={2} xl={2} sx={{mt:{xs:"4px", sm:""}}}>
          <img src={Calender} sx={{height:{sm:"30px",xs:"30px"}}} alt={"Calender.logo"} />
           <Typography className="center-image-txt" sx={{fontSize:{sm:"14px",xs:"12px" }, ml:2, }}>{formattedDateTime}</Typography>           
          </Grid>


          <Grid   className="flx" item sm={6} xs={12} md={4} lg={5} xl={5} sx={{mt:{xs:"4px", sm:""}}}>
           <img src={Hospital} sx={{height:{sm:"30px",xs:"30px"}}} alt={"Calender.logo"} />
           <Typography className="center-image-txt"  sx={{fontSize:{sm:"14px",xs:"12px", }, ml:2, }}>{hospitalData.hospital_or_clinic_name  || "--"}</Typography>
          </Grid>

          <Grid   className="flx" item sm={6} xs={12} md={4} lg={5} xl={4} sx={{mt:{xs:"4px", sm:""}}}>
           <img src={Doctor} sx={{height:{sm:"30px",xs:"30px"}}} alt={"Calender.logo"} />
           <Typography className="center-image-txt" sx={{fontSize:{sm:"14px",xs:"12px", }, ml:2,}}>{hospitalData.doctor_name || "--"}</Typography>
           </Grid>


      </Grid> */}

      <Box
        sx={{
          display: { sm: "flex", xs: "" },
          marginTop: { sm: "", xs: "-10px" },
          justifyContent: "left",
        }}
      >
        <div
          className="flx"
          style={{
            marginBottom: height > width ? "5px" : "0px",
            // maxWidth: "250px",
            whiteSpace: "nowrap",
            marginRight: "20px",
          }}
        >
          <img
            src={Calender}
            sx={{ height: { sm: "30px", xs: "30px" } }}
            alt={"Calender.logo"}
          />
{/* <Typography className="center-image-txt" sx={{fontSize:{sm:"14px",xs:"12px" }, ml:2, }}>{formattedDateTime}</Typography>  */}
          {/* className="center-image-txt"
            sx={{ fontSize: { sm: "14px", xs: "12px" }, ml: 2 }} */}
          <BasicButton
            id="demo-customized-button"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
              fontSize: "12px",
              ml: 2,
              height: "30px",
              textTransform: "none"
              // minWidth: "220px",
            }}
          >
            {selectedDate}
          </BasicButton>
          <StyledMenu
            id="demo-customized-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {/* <MenuItem  sx={{ fontSize: "14px" }} onClick={() => handleDatechange("TODAY")}>
              Today
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} onClick={() => handleDatechange("CURRENT_WEEK")}>
              This Week
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} onClick={() => handleDatechange("CURRENT_MONTH")}>
              This Month
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }}>
              Custom
            </MenuItem> */}
            <MenuItem disableRipple sx={{ fontSize: "14px" }}>
              Select
            </MenuItem>
            <MenuItem
              disableRipple
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Chip
                color="primary"
                label="Today"
                sx={{
                  borderRadius: "5px",
                  width: "90px",
                  backgroundColor: "#41BA8F",
                  cursor: "pointer",
                }}
                onClick={() => handleDatechange("TODAY")}
              />
              <Chip
                color="primary"
                label="This Week"
                sx={{
                  borderRadius: "5px",
                  width: "90px",
                  backgroundColor: "#41BA8F",
                  cursor: "pointer",
                }}
                onClick={() => handleDatechange("CURRENT_WEEK")}
              />
              <Chip
                color="primary"
                label="This Month"
                sx={{
                  borderRadius: "5px",
                  width: "90px",
                  backgroundColor: "#41BA8F",
                  cursor: "pointer",
                }}
                onClick={() => handleDatechange("CURRENT_MONTH")}
              />
            </MenuItem>

            <Divider />
            <MenuItem disableRipple sx={{display: "flex", justifyContent: "space-between"}}>
              <Typography sx={{ fontSize: "14px" }}>Custom</Typography>
              <Chip
                color="primary"
                label="Apply"
                sx={{
                  borderRadius: "5px",
                  width: "90px",
                  backgroundColor: "#41BA8F",
                  cursor: "pointer",
                }}
                onClick={() => handleDatechange("CUSTOM")}
              />
            </MenuItem>
            <MenuItem disableRipple>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  sx={{ height: "30px" }}
                  slotProps={{
                    textField: {
                      sx: {
                        "& .MuiOutlinedInput-root": {
                          width: "135px",
                          height: "30px",
                          padding: 0,
                          "& fieldset": {
                            borderColor: "#C4C4C4",
                          },
                          color: "#000",
                          fontFamily: "Segoe UI",
                          "&:hover fieldset": {
                            borderColor: "#000",
                          },
                          "&.Mui-focused fieldset": {
                            border: "1px solid #C4C4C4",
                          },
                        },
                      },
                    },
                  }}
                  value={customDate.from || dayjs(new Date())}
                  onChange={(newValue) =>
                    setCustomDate({ ...customDate, from: newValue })
                  }
                />

                <DesktopDatePicker
                  sx={{ height: "30px" }}
                  slotProps={{
                    textField: {
                      sx: {
                        "& .MuiOutlinedInput-root": {
                          width: "135px",
                          height: "30px",
                          padding: 0,
                          marginLeft: "20px",
                          "& fieldset": {
                            borderColor: "#C4C4C4",
                          },
                          color: "#000",
                          fontFamily: "Segoe UI",
                          "&:hover fieldset": {
                            borderColor: "#000",
                          },
                          "&.Mui-focused fieldset": {
                            border: "1px solid #C4C4C4",
                          },
                        },
                      },
                    },
                  }}
                  value={customDate.to || dayjs(new Date())}
                  onChange={(newValue) =>
                    setCustomDate({ ...customDate, to: newValue })
                  }
                />
              </LocalizationProvider>
            </MenuItem>
          </StyledMenu>
        </div>

        <div
          className="flx"
          style={{
            marginLeft: height > width ? "0px" : "30px",
            maxWidth: "350px",
            marginRight: "20px",
            marginBottom: height > width ? "5px" : "0px",
          }}
        >
          <img
            src={Hospital}
            sx={{ height: { sm: "30px", xs: "30px" } }}
            alt={"Calender.logo"}
          />
          <Typography
            className="center-image-txt"
            sx={{ fontSize: { sm: "14px", xs: "12px" }, ml: 2 }}
          >
            {hospitalData.hospital_or_clinic_name || "--"}
          </Typography>
        </div>

        <div
          className="flx"
          style={{
            marginLeft: height > width ? "0px" : "30px",
            maxWidth: "350px",
          }}
        >
          <img
            src={Doctor}
            sx={{ height: { sm: "30px", xs: "30px" } }}
            alt={"Calender.logo"}
          />
          {/* <Typography className="center-image-txt" sx={{fontSize:{sm:"14px",xs:"12px", }, ml:2,}}>{hospitalData.doctor_name || "--"}</Typography> */}
          <MultipleSelect
            sx={{ width: "200px", marginLeft: "20px" }}
            value={selectedDoctors.length > 0 ? selectedDoctors : ["Select"]}
            onChange={handlechange}
          >
            <MenuItem value="Select" style={{ display: "none" }}>
              Select
            </MenuItem>
            {doctorList.map((val, i) => (
              <BasicMenuItem key={i} value={val.doctor_name}>
                <ListItemText
                  primary={val.doctor_name}
                  sx={{
                    "& .MuiListItemText-primary": {
                      fontSize: { sm: "14px", xs: "12px" },
                    },
                  }}
                />
                <Checkbox
                  sx={{ padding: 0, marginLeft: "10px" }}
                  checked={selectedDoctors.includes(val.doctor_name)}
                />
              </BasicMenuItem>
            ))}
          </MultipleSelect>
        </div>
      </Box>
    </>
  );
}
