import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// ------------ custom files ------------

import AppRoutes from "../../../Routes/AppRoute";
import NavItems from "../../../Constant/Navmenu";
import NavItemsDashboard from "../../../Constant/NavmenuDashboard";
import CurrentPage from "../../../ActivePage/page";
import BookAppointment from "../../../Popup/BookAppointment";
import "./HomePage.css";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import Menu from "../../ReuseComponents/MobileMenu/Menu";

// ------------ custom icons and images -------
import Logo from "../../../Assets/qgliderlogo.png";
import { Button, Divider ,Typography , Modal, Tooltip } from "@mui/material";
import { Link , useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MobilMenu from "../../ReuseComponents/MobileMenu/Menu";
import Logout from "../../../Assets/navbar/logout.svg";
import DashboardIcon from "../../../Assets/navbar/dashbordIconNew.svg";
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from "react-redux";
import { updateLoggedInData } from "../../Assets/Redux/features/StoreData/userLoginDetails";
// import LogoutPopup from "../../ReuseComponents/Popup/logout/LogoutPopup";
import settingAppointmentData from "../../../Components/Assets/Redux/features/StoreData/settingAppointmentData";

const drawerWidth = 260;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius:"10px"
};




export default function HomePage() {
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [bookModalOpen, setBookModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeNav, setActiveNav] = useState();
  const Location = useLocation();
  const location = CurrentPage();
  const navigate = useNavigate();
  const [showNevbar, setShowNevbar] = useState(["DASHBOARD", "MANAGE_APPOINTMENTS", "MANAGE_USERS", "SETTINGS", "QR_GENERATE" ]);
  const storedData = useSelector((state) => state.userLoginDetails?.loggedInDetails);
  const [fullName, setFullName] = useState("")
  const appointmentDatas = useSelector( (state) => state.settingAppointmentData.appointment_data)



  useEffect(()=>{
    console.log(appointmentDatas, "appointmentDatas");
  },[])
  useEffect(()=>{
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  })

  //FETCHING USER DETAILS FROM STORE
  useEffect(()=>{
    if(storedData?.full_name) {
      setFullName(storedData.full_name);
    }
  },[storedData])

  
  let UserTypeAccess = localStorage.getItem("user_type") || []
 
  let superAdmin = UserTypeAccess.includes("SUPER_ADMIN")

  const accessBlockvalidation = () => {
    if(!superAdmin){    
      setShowNevbar(localStorage.getItem("access_permissions")+",QR_GENERATE");       
    }
  }

  useEffect(() => {
    accessBlockvalidation();    
  },[])

  

  const handleBookModalOpen = () => { 
    if(!showNevbar.includes("MANAGE_APPOINTMENTS")){
      setBookModalOpen(false);     
    }else{
      setBookModalOpen(true);
    }   
   
    // Alert("success", "success message");
  };

  const handleBookModalClose = () => {
    setBookModalOpen(false);
  };

  const logout = () => {
    setOpen(true);
  }
  const DashboardRefresh = () => {
    window.location.href = ("/dashboard")
  }

  const logoutUser = () => {
    localStorage.removeItem("hospital_name")
    localStorage.removeItem("booked_count")
    // localStorage.removeItem("switchOn")
    localStorage.removeItem("DoctorName")
    localStorage.removeItem("full_name")
    localStorage.removeItem("DoctorUid")
    // localStorage.removeItem("user_name")
    navigate("/login")

  }
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {/* {width > height ? ( */}
        {!window.location.pathname.includes("/patientqrscan") &&   <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
              display:{xs:"none",md:"flex"}
            }}
            variant="permanent"
            anchor="left"
          >
            {/* <Toolbar /> */}
            {/* <Divider /> */}
            <Box sx={{ padding: "0px 25px" }}>
              <Box sx={{ padding: "15px 26px" }}>
                <img src={Logo} alt={Logo + ".logo"} width={"150px"}/>
              </Box>
              <Box sx={{ margin: "10px 0px 20px" }}>
                {/* <Tooltip title="No availability day selected today"  disableRipple placement="bottom" arrow > */}
                <Button
                  onClick={handleBookModalOpen}
                  disabled={appointmentDatas == "NO_APPOINTMENT" ? true : false}
                  sx={{
                    borderRadius: "25px",
                    width: "100%",
                    fontSize:"14px",
                    background:!showNevbar.includes("MANAGE_APPOINTMENTS")  || appointmentDatas == "NO_APPOINTMENT" ? "#999999" : "#41BA8F",
                    padding: "10px 5px",
                    color: "white !important",
                    "&:hover": { background:!showNevbar.includes("MANAGE_APPOINTMENTS") ? "#999999" : "#41BA8F" },
                    cursor:!showNevbar.includes("MANAGE_APPOINTMENTS") ? "default" : "pointer"
                   
                  }}
                >
                  + Book an Appointment
                </Button>
                {/* </Tooltip> */}
              </Box>
              <List>


              {NavItemsDashboard.map((text, index) => (  
              <ListItem
                    sx={{ padding: "5px 0px" }}
                    className={location.includes(text.url) || location.includes(text?.activeUrl) ? "activeNavLi" : ""}
                    disablePadding
                    key={index}
                  >
                      {location === "/dashboard" && <ListItemButton sx={{ '&:hover': { backgroundColor: 'transparent' },cursor:!showNevbar.includes(text.key) ? "default" : "pointer"}} disableRipple onClick={DashboardRefresh}>
                        <ListItemIcon sx={{ minWidth: "35px" }}>
                          <img style={{width:"22px", }} src={showNevbar.includes(text.key) ? text.icon : text.fadeIcon} alt={Logout + ".logo"} />
                        </ListItemIcon>
                        <ListItemText
                          className={ showNevbar.includes(text.key) ? location.includes(text.url) || location.includes(text?.activeUrl) && "activeNav" :"activeNavHide"}
                          // primary={"Logout"}
                          primary={<Typography variant="body2" style={{color:showNevbar.includes(text.key) ? '#000000' : "#999999" }}>{text.name}</Typography>}
                        />
                      </ListItemButton>}

                      {location != "/dashboard" && 
                       <Link  to={ showNevbar.includes(text.key) && text.url}  style={{textDecoration:"none", }}>
                        <ListItemButton sx={{ '&:hover': { backgroundColor: 'transparent', },cursor:!showNevbar.includes(text.key) ? "default" : "pointer"}} disableRipple >
                        <ListItemIcon sx={{ minWidth: "35px" }}>
                          <img style={{width:"22px", }} src={showNevbar.includes(text.key) ? text.icon : text.fadeIcon} alt={Logout + ".logo"} />
                        </ListItemIcon>
                        <ListItemText
                          className={ showNevbar.includes(text.key) ? location.includes(text.url) || location.includes(text?.activeUrl) && "activeNav" :"activeNavHide"}
                          // primary={"Logout"}
                          primary={<Typography variant="body2" style={{color:showNevbar.includes(text.key) ? '#000000' : "#999999" }}>{text.name}</Typography>}
                        />
                      </ListItemButton></Link>}
                    </ListItem> ))}










                {NavItems.map((text, index) => (
                  ((UserTypeAccess == "SUPER_ADMIN" ? text.for == "SUPER_ADMIN" : text.for == "EXCEPT_SUPER_ADMIN") || text.for == "ALL_USER") && <ListItem 
                    sx={{ padding: "5px 0px",  "&.MuiButtonBase-root:hover": {
                     
                    }   }}
                    className={location.includes(text.url) || location.includes(text?.activeUrl) ? "activeNavLi" : ""}
                    key={index}
                    disablePadding
                  >
                    <Link 
                      style={{ textDecoration: "none", color: "#000000", "&.MuiButtonBase-root:hover": {
                        bgcolor: "transparent"
                      }   }}
                      to={ showNevbar.includes(text.key) && text.url}
                    >
                      <ListItemButton disableRipple sx={{ '&:hover': { backgroundColor: 'transparent' }, textDecoration: "none",cursor:!showNevbar.includes(text.key) ? "default" : "pointer" }}>
                        <ListItemIcon sx={{ minWidth: "35px", }}>
                          <img style={{width:"22px", }} src={showNevbar.includes(text.key) ? text.icon : text.fadeIcon} alt={text.name + ".logo"} />
                        </ListItemIcon>
                        <ListItemText 
                          className={ showNevbar.includes(text.key) ? location.includes(text.url) || location.includes(text?.activeUrl) && "activeNav" :"activeNavHide"}
                          // primary={text.name} 
                          primary={<Typography variant="body2" style={{color:showNevbar.includes(text.key) ? '#000000' : "#999999" }}>{text.name}</Typography>}
                        />
                      </ListItemButton>
                    </Link>

                  </ListItem>
                ))}
                   <ListItem
                    sx={{ padding: "5px 0px" }}
                    className={location === "/login" ? "activeNavLi" : ""}
                    disablePadding
                  >
                      <ListItemButton onClick={logout}>
                        <ListItemIcon sx={{ minWidth: "35px" }}>
                          <img style={{width:"22px", }} src={Logout} alt={Logout + ".logo"} />
                        </ListItemIcon>
                        <ListItemText
                          className={location === "/login" ? "activeNav" : ""}
                          // primary={"Logout"}
                          primary={<Typography variant="body2" style={{ color: '#000000' }}>{"Logout"}</Typography>}

                        />
                      </ListItemButton>
                    </ListItem>
              </List>

              <Box sx={{ marginTop: "90px" }}>
                <Divider sx={{ margin: "0px -25px" }} />
                <Box component={Link} to="/Profile" sx={{textTransform:"none", textDecoration:"none",  display: "flex", padding: "15px 10px",  alignItems:"center"}}>
                  <Box sx={{ display: "flex", alignItems:"center", gap:1 }}>
                    <AccountCircleIcon style={{ fill: "#41BA8F" }} />
                    <Box sx={{fontSize:"14px", fontWeight:"600", color:"#243665"}}>{fullName || "--"}</Box>
                  </Box>
                  
                </Box>
              </Box>
            </Box>
          </Drawer>}
        {/* ) : (
         ""
        )} */}

        <Box component="main" sx={{ flexGrow: 1, padding:{lg:"10px 25px",md:"10px 25px",sm:"5px 15px", xs:"5px 15px"}  }}>
          <AppRoutes />
        </Box>
      </Box>
     
      {bookModalOpen && <BookAppointment open={bookModalOpen} onClose={handleBookModalClose} />}
      {/* {height > width?( */}
      {!window.location.pathname.includes("/patientqrscan") && <Box sx={{ flexGrow: 1,position:"fixed",width:"100%",bottom:"40px", padding:{lg:"5px 25px",md:"5px 25px",sm:"5px 15px", xs:"5px 15px"},
              display:{md:"none"} }}>
      {<MobilMenu handleBookModalOpen={handleBookModalOpen}/> }
      </Box>}
      {/* ):""} */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Box sx={{backgroundColor:"#FFD7D7",padding:"20px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px"}} id="modal-modal-title">
            <Box sx={{backgroundColor:"#BC123E",padding:"5px",display:"flex",justifyContent:"center",
              alignItems:"center",borderRadius:"20px",width:"40px",height:"40px",position:"absolute",left:"42%"}}>
              <LogoutIcon sx={{fill:"white"}} />
            </Box>
          </Box>
          <Box sx={{padding:"25px 30px 20px",textAlign:"center"}}>
            <Typography id="modal-modal-description" sx={{ mt: 0 }}>
            Are you sure you want to Logout 
            {/* {DeleteUserDetails ? DeleteUserDetails.user_full_name:""}? */}
            </Typography>
          </Box>
          <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",gap:"15px",paddingBottom:"15px"}}>
            <Button onClick={handleClose} sx={{backgroundColor:"#000000", color:"white",minWidth:"95px",padding:"2px",borderRadius:"20px", '&:hover': {
              background: '#000000',}}}>no</Button>
            <Button onClick={logoutUser}  sx={{backgroundColor:"#41BA8F", color:"white",minWidth:"95px",padding:"2px",borderRadius:"20px", '&:hover': {
              background: '#41BA8F',}}}>yes</Button>
            </Box>
        </Box>
      </Modal>
      {/* <LogoutPopup></LogoutPopup> */}

    </>
  );
}
